@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  width: 100%;
  height: 100%;
}
.bg-kassa24-black {
  background-color: rgb(35, 31, 32);
}
.kassa24-color-yellow {
  background-color: #fff229;
}

.hover\:text-kassa24-active:hover {
  color: #ffd200;
}

.focus\:kassa24-input-focus:focus {
  border-color: #ffd200;
}

.hover\:bg-kassa24-active:hover,
.bg-kassa24-active,
.bg-kassa24-gradient {
  background: linear-gradient(315deg, #ffd200 18.29%, #fff229 83.33%);
  color: rgb(35, 31, 32);
}

.text-kassa24-black {
  color: rgb(35, 31, 32);
}

.kassa24-link-blue {
  color: #108ee9;
}
.text-gray-500.kassa24-link-blue {
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.kassa24-link-blue:hover {
  @apply opacity-80;
}

.kassa24-h1 {
  @apply pb-5 text-black text-2xl;
}

.kassa24-h1-py-0 {
  @apply text-black text-2xl pb-0;
}

.kassa24-table-th {
  @apply px-6 py-3 bg-gray-50 text-xs font-bold text-kassa24-black uppercase tracking-wider;
}

.kassa24-table-td {
  @apply px-6 py-4  text-sm text-gray-500 whitespace-pre;
}

.kassa24-small-red-info {
  @apply px-5 pb-2 text-red-600 text-xs;
}

.kassa24-signin-grid {
  @apply lg:grid grid-cols-2 gap-6 px-3 py-3 h-full;
}

.kassa24-signin-left-column {
  @apply mb-5 col-span-1 text-center rounded-lg lg:py-3 lg:mb-0 lg:shadow-xl;
}

.kassa24-auth-input {
  @apply flex appearance-none rounded-none relative px-3 py-3 w-full  border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:kassa24-input-focus focus:z-10 sm:text-sm;
}

.kassa24-input-div {
  @apply flex appearance-none rounded-md items-center relative w-full border bg-white border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:kassa24-input-focus focus:z-10 sm:text-sm;
}
.kassa24-input {
  padding: 13px 0;
  @apply border-0 focus:outline-none rounded-md px-2 w-full;
}
.kassa24-input-error {
  @apply border-red-600 text-red-500 focus:ring-red-500 focus:border-red-500;
}
.kassa24-input-error input {
  @apply text-red-500 placeholder-red-500;
}

.kassa24-icon {
  @apply mx-2;
}
.kassa24-icon-sm {
  width: 20px;
}
.kassa24-icon-md {
  width: 40px;
}
.kassa24-icon-lg {
  width: 80px;
}

.kassa24-w-55 {
  width: 55px;
}

.kassa24-input-userName {
  @apply rounded-t-md;
}

.kassa24-input-captchaValue {
  @apply rounded-b-md;
}

.kassa24-button {
  @apply relative w-full flex justify-center items-center font-bold py-3 px-4 border border-transparent text-sm rounded-md bg-kassa24-gradient;
}
.kassa24-button-small {
  width: 50px;
}
.kassa24-button-medium {
  max-width: 150px;
  @apply w-auto;
}
.kassa24-button-big {
  width: 300px;
}

.kassa24-snackbar-close-button {
  @apply inline-flex rounded-md p-1.5;
}

.rc-pagination {
  @apply w-52;
}

.rc-pagination-total-text {
  @apply text-sm text-gray-700 mb-4 lg:absolute lg:left-0 lg:top-1/2 lg:-mt-2;
}
.rc-pagination-item {
  @apply hidden;
}
.rc-pagination-prev {
  @apply cursor-pointer kassa24-w-55 h-10 relative inline-block align-top px-4 py-2 border border-gray-300  font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 border-r-0 rounded-r-none;
}
.rc-pagination-next {
  @apply cursor-pointer kassa24-w-55 h-10 relative inline-block align-top px-4 py-2 border border-gray-300 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  rounded-l-none;
}
.rc-pagination-options {
  @apply float-left cursor-pointer w-16 h-10 inline-block align-top  border border-gray-300 font-medium rounded-md text-gray-700 box-border;
}
.rc-select {
  @apply relative inline-block w-auto h-full;
}
.rc-select-selector {
  @apply flex relative h-full;
}
.rc-select-selection-item {
  @apply absolute top-0 left-1 px-2 py-2;
}
.rc-select-arrow {
  @apply absolute  top-1 right-4;
}
.rc-select-arrow-icon::after {
  content: '';
  @apply w-0 h-0 border-4 border-transparent border-t-gray-600 inline-block translate-y-1;
}
.rc-select-dropdown-hidden {
  @apply hidden;
}
.rc-select-selection-search {
  @apply w-full;
}
.rc-select-dropdown {
  @apply bg-white  text-left mt-1 py-2 border border-gray-300 font-medium rounded-md text-gray-700 box-border kassa24-button-small;
}
.rc-select-selection-search-input {
  @apply appearance-none outline-none border-0 w-full;
}

.rc-select-item-option-state {
  @apply hidden;
}
.rc-select-item {
  @apply px-4;
}

.rc-select-item-option-selected {
  @apply text-kassa24-black kassa24-color-yellow;
}

.rc-pagination-prev:hover,
.rc-pagination-next:hover,
.kassa24-border-yellow {
  border-color: #ffd200;
  background-color: rgb(255, 210, 0, 0.2);
}

.kassa24-sort-label {
  @apply flex cursor-pointer items-center justify-start;
}
.kassa24-sort-label .kassa24-sort-arrow {
  @apply opacity-0 w-4 pl-1;
}

.kassa24-active-sort .kassa24-sort-arrow {
  @apply opacity-100;
}

.kassa24-sort-label:hover .kassa24-sort-arrow {
  @apply inline-block opacity-80;
}

.kassa24-search-border {
  @apply border-t-2 border-l-2 border-r-2 border-gray-100 rounded-t-lg border-b-2;
}
.kassa24-skeleton {
  @apply bg-gray-200 animate-pulse;
}

.kassa24-datepicker {
  @apply kassa24-input-div;
}
.kassa24-datepicker input {
  @apply kassa24-input bg-transparent;
}

.react-datepicker__day--today,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  @apply kassa24-color-yellow text-black;
}

.react-datepicker__day:hover {
  @apply kassa24-color-yellow text-black opacity-80;
}

.dot-spin {
  width: 6px;
  height: 6px;
  box-shadow: 0 -13px 0 0 rgb(35, 31, 32),
    7.72984px -7.72984px 0 0 rgb(35, 31, 32), 13px 0 0 0 rgb(35, 31, 32),
    7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
    0 13px 0 0 rgba(152, 128, 255, 0),
    -7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
    -13px 0 0 0 rgba(152, 128, 255, 0),
    -7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;

  @apply my-2 relative rounded-md bg-transparent text-transparent;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -13px 0 0 rgb(35, 31, 32),
      7.72984px -7.72984px 0 0 rgb(35, 31, 32), 13px 0 0 0 rgb(35, 31, 32),
      7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      0 13px 0 0 rgba(152, 128, 255, 0),
      -7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      -13px 0 0 0 rgba(152, 128, 255, 0),
      -7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -13px 0 0 rgba(152, 128, 255, 0),
      7.72984px -7.72984px 0 0 rgb(35, 31, 32), 13px 0 0 0 rgb(35, 31, 32),
      7.72984px 7.72984px 0 0 rgb(35, 31, 32), 0 13px 0 0 rgba(152, 128, 255, 0),
      -7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      -13px 0 0 0 rgba(152, 128, 255, 0),
      -7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -13px 0 0 rgba(152, 128, 255, 0),
      7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0),
      13px 0 0 0 rgb(35, 31, 32), 7.72984px 7.72984px 0 0 rgb(35, 31, 32),
      0 13px 0 0 rgb(35, 31, 32),
      -7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      -13px 0 0 0 rgba(152, 128, 255, 0),
      -7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -13px 0 0 rgba(152, 128, 255, 0),
      7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0),
      13px 0 0 0 rgba(152, 128, 255, 0), 7.72984px 7.72984px 0 0 rgb(35, 31, 32),
      0 13px 0 0 rgb(35, 31, 32), -7.72984px 7.72984px 0 0 rgb(35, 31, 32),
      -13px 0 0 0 rgba(152, 128, 255, 0),
      -7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -13px 0 0 rgba(152, 128, 255, 0),
      7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0),
      13px 0 0 0 rgba(152, 128, 255, 0),
      7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0), 0 13px 0 0 rgb(35, 31, 32),
      -7.72984px 7.72984px 0 0 rgb(35, 31, 32), -13px 0 0 0 rgb(35, 31, 32),
      -7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -13px 0 0 rgba(152, 128, 255, 0),
      7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0),
      13px 0 0 0 rgba(152, 128, 255, 0),
      7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      0 13px 0 0 rgba(152, 128, 255, 0),
      -7.72984px 7.72984px 0 0 rgb(35, 31, 32), -13px 0 0 0 rgb(35, 31, 32),
      -7.72984px -7.72984px 0 0 rgb(35, 31, 32);
  }
  75% {
    box-shadow: 0 -13px 0 0 rgb(35, 31, 32),
      7.72984px -7.72984px 0 0 rgba(152, 128, 255, 0),
      13px 0 0 0 rgba(152, 128, 255, 0),
      7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      0 13px 0 0 rgba(152, 128, 255, 0),
      -7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      -13px 0 0 0 rgb(35, 31, 32), -7.72984px -7.72984px 0 0 rgb(35, 31, 32);
  }
  87.5% {
    box-shadow: 0 -13px 0 0 rgb(35, 31, 32),
      7.72984px -7.72984px 0 0 rgb(35, 31, 32),
      13px 0 0 0 rgba(152, 128, 255, 0),
      7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      0 13px 0 0 rgba(152, 128, 255, 0),
      -7.72984px 7.72984px 0 0 rgba(152, 128, 255, 0),
      -13px 0 0 0 rgba(152, 128, 255, 0),
      -7.72984px -7.72984px 0 0 rgb(35, 31, 32);
  }
}

.kassa24-tab-item {
  @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer;
}

.kassa24-tab-active {
  color: #108ee9;
  border-color: #108ee9;
}

.kassa24-icon-button {
  @apply rounded-full items-center bg-none bg-gray-200 px-3 py-3 border-0;
}

.kassa24-icon-button svg {
  @apply text-gray-600;
}
.kassa24-icon-button p {
  @apply text-gray-600 ml-2 text-xs;
}
.kassa24-icon-button:hover {
  @apply opacity-80;
}
.kassa24-close-btn {
  @apply absolute right-2 top-2 bg-red-100;
}
.kassa24-close-btn svg {
  @apply text-red-600;
}
.ql-toolbar.ql-snow {
  @apply rounded-t-md border-gray-300;
}
.ql-container.ql-snow {
  @apply rounded-b-md border-gray-300;
}
.kassa24-modal {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}
.kassa24-modal-content {
  @apply relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;
}
.kassa24-success-text {
  @apply text-green-600;
}
.kassa24-pass-rule {
  @apply flex items-center pb-2;
}
.kassa24-pass-rule div,
.kassa24-pass-rule svg {
  @apply mr-2;
}
.kassa24-pass-rule svg {
  margin-left: -1px;
}
.circleOutline {
  @apply border-2 border-gray-500 rounded-full w-4 h-4 box-border;
}
.kassa24-profile-account {
  @apply flex items-center px-4 py-2 cursor-pointer;
}
.kassa24-profile-menu {
  @apply origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10;
}
